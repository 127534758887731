<template>
  <div>
    <div class="mb-1"><strong class="mr-2">{{name}}</strong><b-button variant="outline-primary" size="sm" v-on:click="editStart">edit</b-button></div>
    <div v-if="!edit" class="mb-2">
      {{valueNew}}
    </div>
      <b-form-input v-if="edit"
                    id="input"
                    v-model="valueNew"
                    :placeholder="name"
                    required
                    class="mb-2">
      </b-form-input>
      <span v-if="!busy">
        <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">save</b-button>
        <b-button v-if="edit" variant="outline-primary" size="sm" v-on:click="cancel">cancel</b-button>
      </span>
  </div>
</template>

<script>
export default {
  name: 'EditProperty',
  props: ['id', 'name', 'partition', 'value'],
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.valueNew && this.edit
    }
  },
  created: function () {
  },
  data () {
    return {
      busy: false,
      edit: false,
      valueNew: this.value,
      old: ''
    }
  },
  methods: {
    cancel: function () {
      this.$logger.debug('cancel save comments')
      this.edit = false
      this.valueNew = this.old
    },
    editStart: function () {
      this.old = this.valueNew
      this.edit = true
    },
    save: async function () {
      this.$logger.debug(`save start`)
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/property`
        let params = {
          body: {
            property: {
              partition: this.partition,
              id: this.id,
              propertyName: this.name,
              propertyValue: this.valueNew
            }
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.edit = false
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.busy = false
    }
  }
}
</script>

<style>
</style>
