<template>
  <div>
              <div class="mb-2"><strong class="mr-2">{{nameProp}}</strong><b-button class="mr-2" variant="outline-primary" size="sm" v-on:click="edit = true">edit</b-button></div>
              <div class="mb-2">
                <div v-for="value in values" :key="value">
                  {{value}}
                  <b-button v-if="edit" variant="outline-primary" size="sm" v-on:click="deletePropertyList(value)">remove</b-button>
                </div>
              </div>
              <div v-if="edit">
                <span v-if="!busy">
                <b-form-select class="mb-2" v-model="selected" :options="options"/>
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="savePropertyList">save</b-button>
                <b-button class="mr-2" variant="outline-primary" size="sm" v-on:click="edit = false">done</b-button>
                </span>
                <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
              </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'PropertySelectList',
  props: ['idProp', 'nameProp', 'optionsProp', 'partitionProp', 'tableProp', 'valuesProp'],
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.selected
    }
  },
  created: function () {
    this.values = this.valuesProp
    this.buildOptions()
  },
  data () {
    return {
      busy: false,
      edit: false,
      options: [],
      selected: '',
      values: []
    }
  },
  methods: {
    buildOptions: function () {
      let optionsTemp = []
      optionsTemp[0] = { value: null, text: 'select ' + this.nameProp }
      for (let i = 0, len = this.optionsProp.length; i < len; i++) {
        optionsTemp[i + 1] = { value: this.optionsProp[i], text: this.optionsProp[i] }
      }
      if (this.values.length > 0) {
        for (let i = 0, len = this.values.length; i < len; i++) {
          _.remove(optionsTemp, _.matchesProperty('value', this.values[i]))
        }
      }
      this.options = optionsTemp
    },
    deletePropertyList: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/propertylist`
        let params = {
          body: {
            property: {
              partition: this.partitionProp,
              id: this.idProp,
              propertyName: this.nameProp,
              propertyValue: value,
              table: this.tableProp
            }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        temp.sort()
        this.values = temp
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    savePropertyList: async function () {
      this.$logger.debug(`saving start`)
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/propertylist`
        let params = {
          body: {
            property: {
              partition: this.partitionProp,
              id: this.idProp,
              propertyName: this.nameProp,
              propertyValue: this.selected,
              table: this.tableProp
            }
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.values.push(this.selected)
        this.values.sort()
        this.selected = null
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.busy = false
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
