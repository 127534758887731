<template>
<div>
  <b-container class="bg-white py-4">
    <b-row class="mb-4" v-if="!permissions.readUsers">
      <b-col>
        No access
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-show="loading">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.readUsers">
      <b-col>
        <b-container>
          <b-row v-if="show.users">
            <b-col>
              <div v-if="permissions.createUsers">
                <b-btn class="mb-4" variant="outline-primary" @click="show.users = false; show.createUser = true">add user</b-btn>
              </div>
              <b-form-group horizontal label="Filter" class="mb-2">
                <b-input-group>
                  <b-form-input v-model="filter" placeholder="Type to Search" />
                    <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
                </b-input-group>
              </b-form-group>
              <b-table id="usertable"
                striped
                hover
                :items="users"
                :fields="fields"
                :filter="filter"
                @row-clicked="showUser"></b-table>
            </b-col>
          </b-row>
          <b-row v-if="show.account">
            <b-col>
              <div><b-btn class="mb-4" variant="outline-primary" @click="getUsers">back to users</b-btn></div>
              <div class="mb-2"><strong>id:</strong> {{account.id}}</div>
              <div class="mb-2"><strong>email:</strong> {{account.email}}</div>
              <div class="mb-4">
              <div><strong>user groups</strong></div>
              <div v-for="group in account.userGroups" :key="group">
                {{group}}
              </div>
              </div>
              <div>
                <b-btn class="mr-2" variant="outline-primary" @click="show.editUser = true; show.account = false">edit</b-btn>
                <i v-if="deleting" class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                <span v-if="!deleting">
                  <b-btn v-b-toggle.collapse1 variant="danger">delete</b-btn>
                  <b-collapse id="collapse1" class="mt-2">
                    <b-card>
                      <div>Are you sure you want to delete?</div>
                        <b-btn variant="danger" @click="deleteUser">yes, delete</b-btn>
                    </b-card>
                  </b-collapse>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="show.createUser">
            <b-col v-if="permissions.createUsers">
              <div><b-btn class="mb-4" variant="outline-primary" @click="getUsers">back to users</b-btn></div>
              <div>original email</div>
              <b-form-input v-model="originalEmail" placeholder="id" class="mb-2"/>
              <b-btn variant="primary" @click="populateUser">populate user</b-btn>
              <div>id</div>
              <b-form-input v-model="account.id" placeholder="id" class="mb-2"/>
              <div>email</div>
              <b-form-input v-model="account.email" placeholder="email" class="mb-2"/>
              <b-btn v-if="validateCreateUser" variant="primary" @click="createUser">create user</b-btn>
            </b-col>
          </b-row>
          <b-row v-if="show.editUser">
            <b-col v-if="permissions.createUsers">
              <div><b-btn class="mb-4" variant="outline-primary" @click="getUsers">back to users</b-btn></div>
              <div class="mb-4"><strong>id: </strong>{{account.id}}</div>
              <edit-property :id="account.id"
                             name="email"
                             partition="users"
                             :value="account.email"
                             class="mb-4" />
              <property-select-list :idProp="account.iid"
                                    nameProp="usergroups"
                                    partitionProp="users"
                                    tableProp="users"
                                    :valuesProp="account.userGroups"
                                    :optionsProp="userGroupsOptions"
                                    class="mb-4" />
              <div><b-btn class="mb-4" variant="outline-primary" @click="reloadUser">done</b-btn></div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import EditProperty from '@/components/EditProperty.vue'
import PropertySelectList from '@/components/cosmos/PropertySelectList.vue'

export default {
  beforeDestroy () {
  },
  components: {
    EditProperty,
    PropertySelectList
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validateCreateUser: function () {
      return this.account.id && this.account.email
    }
  },
  created: async function () {
    document.title = "KPMG Regulatory Horizon: Users"
    this.$stat.log({ page: 'users', action: 'open users' })
    this.permissions.readUsers = ac.can(this.user.acgroups).readAny('user').granted
    this.permissions.createUsers = ac.can(this.user.acgroups).createAny('user').granted
    if (this.permissions.readUsers) {
      this.getUsers()
      const groups = await this.$Amplify.API.get('cosmos', '/standard/usergroup')
      this.userGroupsOptions = groups.map(group => group.name)
    } else {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      deleting: false,
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'groups',
          sortable: true
        }
      ],
      filter: '',
      originalEmail: '',
      permissions: {},
      show: {
        account: false,
        createUser: false,
        users: true
      },
      account: {},
      userGroupsOptions: [],
      users: []
    }
  },
  methods: {
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    createUser: async function () {
      this.$logger.debug('createUser started')
      try {
        let apiName = 'cosmos'
        let path = '/user'
        let params = {
          body: {
            user: this.account
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.account = response
        this.clearShow()
        this.show.account = true
      } catch (e) {
        this.$logger.warn('saving user error', e)
      }
    },
    deleteUser: async function () {
      this.$logger.debug('deleteUser started')
      this.deleting = true
      try {
        let apiName = 'cosmos'
        let path = `/user/${this.account.iid}`
        let response = await this.$Amplify.API.del(apiName, path)
        this.$logger.debug('response', response)
        this.deleting = false
        this.getUsers()
      } catch (e) {
        this.$logger.warn('getting users error' + e)
        this.deleting = false
      }
    },
    getUsers: async function () {
      this.$logger.debug('getUsers started')
      this.clearShow()
      this.account = { id: null, email: null }
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/users'
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        let temp = _.sortBy(response, 'id')
        for (let i = 0, len = temp.length; i < len; i++) {
          if (temp[i].userGroups) {
            temp[i].groups = JSON.stringify(temp[i].userGroups)
          }
        }
        this.users = temp
        this.show.users = true
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting users error' + e)
      }
    },
    populateUser: function () {
      this.account.id = this.originalEmail.toLowerCase()
      this.account.email = this.originalEmail.toLowerCase()
    },
    reloadUser: async function () {
      this.$logger.debug('reloadUser started')
      this.clearShow()
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/user/${this.account.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.account = response
        this.show.account = true
        this.loading = false
      } catch (e) {
        this.$logger.warn('reloadUser error' + e)
      }
    },
    showUser: function (account, index, event) {
      this.account = account
      if (!this.account.userGroups) {
        this.account.userGroups = []
      }
      this.clearShow()
      this.show.account = true
    }
  }
}
</script>

<style>
</style>
